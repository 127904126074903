import { FC, Fragment } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useParams } from "react-router-dom";
import { awdApi } from "store/reducers/awdApi";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { iconColor } from "utils/themes";
import { WaterDrop } from "@mui/icons-material";

export const NavigationMeteostation: FC = () => {
  const { icao } = useParams();
  const query = awdApi.endpoints.getAirportLinkSettings.useQuery(
    icao ? { airportId: icao } : skipToken
  );
  const settings = query.data;

  if (!settings?.meteostation_url) return <Fragment></Fragment>;

  return (
    <ListItemButton href={`/${icao}/meteostation`}>
    <ListItemIcon>
    <WaterDrop htmlColor={iconColor} />
    </ListItemIcon>
    <ListItemText primary="Meteostation" />
    </ListItemButton>
  );
};
