import React from "react";
import { getHomepageArticle } from "utils/contentful";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { ArticleTitle } from "components/content/ArticleTitle";
import { Article } from "components/content/Article";
import { Stack } from "components/layout/Stack";
import { StoreBadges } from "components/storeBadges/StoreBadges";
import { localApi } from "store/reducers/localApi";
import ExplanationArticle from "components/content/ExplanationArticle.mdx";
import { useDefaultOptions } from "components/content/defaultOptions";
import { useContentfulData } from "utils/useContentfulData";

export const ContentfulArticle = () => {
  const isMobileApp =
    localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app;
  const articleData = useContentfulData(getHomepageArticle);

  const defaultOptions = useDefaultOptions();
  const options: Options = {
    renderNode: {
      ...defaultOptions.renderNode,
      [BLOCKS.HEADING_1]: (_node, children) => (
        <Stack
          gap="0.25rem"
          horizontal
          css={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <ArticleTitle>{children}</ArticleTitle>
          {!isMobileApp && (
            <div css={{ transform: "translateY(-1rem)", flexShrink: 0 }}>
              <StoreBadges />
            </div>
          )}
        </Stack>
      ),
      [BLOCKS.TABLE]: (_node, children) => (
        <div css={{ overflowX: "auto" }}>
          <table css={{ width: "100%", whiteSpace: "nowrap" }}>
            {children}
          </table>
        </div>
      ),
    },
  };

  // fallback
  if (!articleData || !articleData?.article) {
    return (
      <Article css={{ maxWidth: "100%", overflowX: "hidden" }}>
        <div id="scrollToArticle">
          <Stack
            gap="0.25rem"
            horizontal
            css={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <ArticleTitle css={{}}>
              Weather Nowcasting for Aviation
            </ArticleTitle>
            {!isMobileApp && (
              <div css={{ transform: "translateY(-1rem)", flexShrink: 0 }}>
                <StoreBadges />
              </div>
            )}
          </Stack>
          <ExplanationArticle />
        </div>
      </Article>
    );
  }

  return (
    <Article css={{ maxWidth: "100%", overflowX: "hidden" }}>
      <div id="scrollToArticle">
        {articleData
          ? documentToReactComponents(articleData.article, options)
          : null}
      </div>
    </Article>
  );
};
