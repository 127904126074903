import { AirportWeatherResponse, UserSettingsResponse, WeatherReport } from "awd-server-api";
import { describeAirport } from "logic/airport/describe";
import { describeUnitValue } from "logic/unit/describe";
import { describeSettingsOrDefault } from "logic/user/me/settings/describe";
import { describeWeatherQueryError } from "logic/weather/error/describe";
import spacetime, { Spacetime } from "spacetime";
import { LastWeatherReport } from "store/reducers/localApi";
import { FlightRulesCode, RunwayType } from "store/reducers/localStateSlice";
import soft from "timezone-soft";
import { bound } from "utils/class";
import { activeArrow, calculateWindToRunway, q2u } from "utils/global.functions";
import { isNonNullable } from "utils/general";

export function describeAirportWeatherQuery(
  query: {
    originalArgs?: { icao?: string };
    data?: AirportWeatherResponse;
    isFetching: boolean;
    error?: unknown;
    isLoading: boolean;
    isSuccess: boolean;
  },
  lastReportQuery: {
    data?: LastWeatherReport | null;
    error?: unknown;
  }
) {
  const reportSource =
    query.data?.weather == null &&
    lastReportQuery.data?.airport != null &&
    describeAirport(lastReportQuery.data.airport).hasIcao(query.originalArgs?.icao)
      ? {
          type: "stored_last_report" as const,
          weather: lastReportQuery.data.report,
          queryError: lastReportQuery.error,
        }
      : {
          type: "awd_server" as const,
          weather: query.data?.weather,
          queryError: query.error,
        };

  return bound({
    isKioskDisabled() {
      return query.data?.canShowKiosk === false;
    },
    reportSource,
    query,
    report() {
      if (reportSource.weather == null) return undefined;

      return describeAirportWeatherReport(reportSource.weather);
    },
    describeQueryError() {
      return reportSource.queryError != null
        ? describeWeatherQueryError(reportSource.queryError)
        : undefined;
    },
  });
}

export function describeAirportWeatherReport(
  report: WeatherReport,
  opts?: { settings?: UserSettingsResponse }
) {
  const settings = describeSettingsOrDefault(opts?.settings);
  return bound({
    report,

    getIcao() {
      return report.icao_id;
    },

    withSettings(settings: UserSettingsResponse | undefined) {
      return describeAirportWeatherReport(report, { ...opts, settings });
    },

    formatElevation() {
      if (!report.elevation) return undefined;

      return describeUnitValue(report.elevation)
      ?.convertToUnit(settings.settings.ceiling_unit, true)
      ?.format({ postprocess: "round" });
    },

    formatSunrise() {
      return this.getFormattedLocalTime("sunrise", "short");
    },

    formatSunset() {
      return this.getFormattedLocalTime("sunset", "short");
    },

    getFormattedLocalTime(timeSource: string, output: string, input?: string, updateTime?: (time: Spacetime) => Spacetime ) {
      if (!report.nowcast?.publish_time || !report.next_sunrise || !report.next_sunset) {
        return undefined;
      }

      let localTimeString;
      if (timeSource === "sunrise") {
        localTimeString = report.next_sunrise;
      } else if (timeSource === "sunset") {
        localTimeString = report.next_sunset;
      } else if (timeSource === "input") {
        if (!input) {
          return undefined;
        }
        localTimeString = input;
      } else {
        localTimeString = report.nowcast.publish_time;
      }

      // timeZone got from weather backend json, field nowcast.publish_time or sunset/sunrise timezone
      const timeZone = localTimeString.substring(
        localTimeString.indexOf("[") + 1,
        localTimeString.indexOf("]")
      );

      // datetime got from local browser, transformed to take epoch for airport or sunset/sunrise time
      let localTime;

      if (timeSource === "sunset" || timeSource === "sunrise" || timeSource === "input") {
        localTime = localTimeString.substring(0, 16); // "2024-03-21T19:08".length
      } else {
        localTime = new Date().getTime();
      }

      let s = spacetime(localTime, timeZone);
      if (updateTime) {
        s = updateTime(s);
      }

      const timeFormat = settings.settings.time_format === "24h" ? "{time-24}" : "{time-12}";

      const dateTime =
        output === "long"
          ? s.format(`{month-short} {date} at ${timeFormat}`)
          : s.format(`${timeFormat}`);

      let zoneAbbrev = soft(timeZone)[0];
      if (s.isDST()) {
        return `${dateTime} ${zoneAbbrev?.daylight.abbr}`;
      } else {
        return `${dateTime} ${zoneAbbrev?.standard.abbr}`;
      }
    },

    getTextReport(source: "taf" | "metar" | "aw-metar" | "aw-taf") {
      if (source === "taf" || source === "metar") return report[source]?.original_report;
      else if (source === "aw-metar") return report.nowcast?.synthetic_metar_report;
      else if (source === "aw-taf") return report.nowcast?.synthetic_taf_report;
      else return "N/A";
    },

    formatTextReport(source: "taf" | "metar" | "aw-metar" | "aw-taf") {
      let textReport = this.getTextReport(source);

      if (source === "taf" || source === "aw-taf") {
        textReport = textReport?.replaceAll("AW-TAF ", "");
        textReport = textReport?.replaceAll("TAF ", "");

        // https://www.nws.noaa.gov/directives/sym/pd01008013curr.pdf
        textReport = textReport?.replaceAll(" FM",                       "\n     FM");    // B2.9.2
        textReport = textReport?.replaceAll(/ (PROB\d{2} TEMPO|TEMPO)/g, "\n     $&");    // B2.9.3
        textReport = textReport?.replaceAll(/ (PROB\d{2})(?! TEMPO)/g,   "\n    $&");     // B2.9.4
        textReport = textReport?.replaceAll(" BECMG",                    "\n     BECMG"); // non-standard
        textReport = textReport?.replaceAll(" AMD",                      "\n     AMD");   // D4.3
        textReport = textReport?.replaceAll("AMD ",                      "AMD\n");        // D4.3
        textReport = textReport?.replaceAll(" RMK",                      "\nRMK");        // non-standard
      } else {
        textReport = textReport?.replaceAll("AW-METAR ", "");
        textReport = textReport?.replaceAll("METAR ", "");
      }
      return textReport;

    },

    hasOriginalReport(source: "taf" | "metar") {
      return this.getTextReport(source) != null;
    },

    isOriginalReportUnavailable(source: "taf" | "metar") {
      return !this.hasOriginalReport(source);
    },

    selectMetarSource(args: { toggledToAw: boolean }) {
      const source = report[args.toggledToAw ? "nowcast" : "metar"];

      return bound({
        source,

        getCurrentWind() {
          return source?.wind_10m_agl?.time_steps?.[0];
        },

        getWindAngleToRunway(runway: RunwayType) {
          const wind = this.getCurrentWind();
          if (
            wind?.from_direction == null ||
            !wind.from_direction.value ||
            !wind.from_direction.unit
          )
            return undefined;

          const value = wind.from_direction.value;
          const unit = wind.from_direction.unit;

          return calculateWindToRunway({ value, unit }, runway);
        },

        shouldShowWindAlertColor() {
          return !!this.getCurrentWind()?.from_direction?.meaning;
        },

        getGustUnit() {
          return this.getCurrentWind()?.gust_speed?.unit;
        },

        formatGustSpeed() {
          const gust = this.getCurrentWind()?.gust_speed;

          if (!gust) return undefined;

          return describeUnitValue(gust)
            ?.convertToUnit(settings.settings.speed_unit)
            ?.format({ postprocess: "round" });
        },

        getWindUnit() {
          return this.getCurrentWind()?.speed?.unit;
        },

        formatWindSpeed() {
          const speed = this.getCurrentWind()?.speed;

          if (!speed) return undefined;

          return describeUnitValue(speed)
            ?.convertToUnit(settings.settings.speed_unit, true)
            ?.format({ postprocess: "round" });
        },

        formatWindAngle() {
          const direction = this.getCurrentWind()?.from_direction;

          if (!direction || !direction.value || !direction.unit) return undefined;
          if (direction.meaning === "no data") {
            return "no data";
          }

          const value = direction.value;
          const unit = direction.unit;

          return [
            q2u({ value, unit }, "degreeT", "round"),
            direction.unit === "degreeT" ? "°" : "",
          ].join("");
        },

        formatWindAngleReturnNumber(): number | undefined {
          const direction = this.getCurrentWind()?.from_direction;

          if (!direction || (!direction.value && direction.value !== 0) || !direction.unit)
            return undefined;
          if (direction.meaning === "no data") {
            return undefined;
          }

          const value = direction.value;
          const unit = direction.unit;

          return q2u({ value, unit }, "degreeT", "round");
        },

        getActiveWindArrows() {
          const wind = this.getCurrentWind();
          if (wind?.speed?.value == null) return undefined;

          return activeArrow(Math.ceil(wind.speed.value));
        },

        doesWindDirectionHaveSpecialMeaning() {
          return this.getCurrentWind()?.from_direction?.meaning != null;
        },

        isWindVariable() {
          return this.getCurrentWind()?.from_direction?.meaning === "variable";
        },

        isWindCalm() {
          // "The speed is converted to knots, which is then rounded to the nearest integer"
          // https://gitlab.hq.meandair.com/core/product/-/issues/1850#note_28224
          return Math.round(q2u(this.getCurrentWind()?.speed, "kt") ?? -1) === 0;
        },

        getCloudCoverOctas() {
          const cloudCover = this.getCurrentQuantity("cloud_cover");

          if (!cloudCover || cloudCover.meaning) return undefined;

          return Math.round((cloudCover?.value ?? 100) / 100.0 * 8.0);
        },

        getFlightRulesCode() {
          const flightRulesCode = this.getCurrentQuantity("flight_rules")?.meaning?.toLocaleLowerCase() ?? "default";
          return flightRulesCode as FlightRulesCode;
        },

        formatCloudCover() {
          const octas = this.getCloudCoverOctas();

          if (!octas && octas !== 0) return undefined;

          if (octas === 0) {
            return "sky clear";
          } else if (octas <= 2) {
            return "few clouds";
          } else if (octas <= 4) {
            return "scattered clouds";
          } else if (octas <= 7) {
            return "broken clouds";
          } else {
            return "overcast";
          }
        },

        formatCurrentVisibility() {
          const visibility = this.getCurrentQuantity("surface_visibility");

          if (visibility == null) return undefined;

          return describeUnitValue(visibility)
            ?.convertToUnit(settings.settings.visibility_unit, true)
            ?.format({ postprocess: "round" });
        },

        formatCurrentTemperature() {
          const temperature = this.getCurrentQuantity("air_temperature_2m_agl");

          if (temperature == null) return undefined;

          return describeUnitValue(temperature)
            ?.convertToUnit(settings.settings.temperature_unit, true)
            ?.format({ postprocess: "round" });
        },

        formatCurrentQNH() {
          const pressure = this.getCurrentQuantity("air_pressure_qnh");

          if (pressure == null) return undefined;

          return describeUnitValue(pressure)
            ?.convertToUnit(settings.settings.pressure_unit)
            ?.format({ postprocess: "floor" });
        },

        getTime() {
          return args.toggledToAw ? report.nowcast?.publish_time : report.metar?.observation_time;
        },

        getCurrent(property: QuantityProperty) {
          return source?.[property]?.time_steps?.[0];
        },

        getCurrentQuantity(property: QuantityProperty) {
          return this.getCurrent(property)?.quantity;
        },
      });
    },

    selectForecastSource(args: { toggledToAw: boolean }) {
      const source = report[args.toggledToAw ? "nowcast" : "taf"];
      return bound({
        source,
        getTime() {
          return args.toggledToAw ? report.nowcast?.publish_time : report.taf?.issued_time;
        },
        getVisibility() {
          return this.filterTimesteps(source?.surface_visibility);
        },
        getCeiling() {
          return this.filterTimesteps(source?.ceiling_agl);
        },
        getCloudCover() {
          return this.filterTimesteps(source?.cloud_cover);
        },
        getFlightRules() {
          return this.filterTimesteps(source?.flight_rules);
        },

        shouldShowWindAlertColor(index: number) {
          return !!this.getWindAtIndex(index)?.from_direction?.meaning;
        },
        getRelevantWindSteps() {
          return this.filterTimesteps(source?.wind_10m_agl);
        },
        getWindAtIndex(index: number) {
          const relevantSteps = this.getRelevantWindSteps();
          return relevantSteps?.time_steps?.[index];
        },
        formatWindAngleAtIndex(index: number) {
          const direction = this.getWindAtIndex(index)?.from_direction;

          if (!direction || !direction.value || !direction.unit) return undefined;
          if (direction.meaning === "no data") {
            return "no data";
          }

          const value = direction.value;
          const unit = direction.unit;

          return [
            q2u({ value, unit }, "degreeT", "round"),
            direction.unit === "degreeT" ? "°" : "",
          ].join("");
        },
        formatWindSpeedAtIndex(index: number) {
          const speed = this.getWindAtIndex(index)?.speed;

          if (!speed) return undefined;

          return describeUnitValue(speed)
            ?.convertToUnit(settings.settings.speed_unit)
            ?.format({ postprocess: "round" });
        },
        formatGustSpeedAtIndex(index: number) {
          const gust = this.getWindAtIndex(index)?.gust_speed;

          if (!gust) return undefined;

          return describeUnitValue(gust)
            ?.convertToUnit(settings.settings.speed_unit)
            ?.format({ postprocess: "round" });
        },
        getActiveWindArrowsForecast(index: number) {
          const wind = this.getRelevantWindSteps()?.time_steps?.[index];

          if (wind?.speed?.value == null) return undefined;
          return activeArrow(Math.ceil(wind.speed.value));
        },
        getWind() {
          const filteredRelevantWindSteps = this.filterTimesteps(source?.wind_10m_agl, true);
          return bound({
            ...filteredRelevantWindSteps,
            getValidTime() {
              return this.time_steps?.map((i) => i.valid_time);
            },
            getFormattedTimeSteps() {
              return this.getValidTime()?.map((i) => {
                if (!i) return "HH:MM";
                const s = formatTimeToCompare(i, true);
                return s.format("{hour-24-pad}:{minute-pad}");
              });
            },
            getFormattedTimeStepsInObject() {
              return this.getValidTime()
                ?.filter(isNonNullable)
                ?.map((el, i, a) => {
                  const s = formatTimeToCompare(el, true);
                  return {
                    label: s.format("{hour-24-pad}:{minute-pad}"),
                    // 300 is the max value of the input slider
                    value: (i * 300) / (a.length - 1),
                  };
                });
            },
            getFormattedDays() {
              return this.getValidTime()?.map((i) => {
                if (!i) return "MM DD";
                const d = formatTimeToCompare(i, true);
                return d.format("{month-short} {date-pad}");
              });
            },
            getUniqueDays() {
              return this.getFormattedDays()?.reduce((acc: string[], v) => {
                if (!acc.some((el) => el.includes(v))) acc.push(v);
                return acc;
              }, []);
            },
          });
        },
        isWindCalmAtIndex(index: number) {
          // "The speed is converted to knots, which is then rounded to the nearest integer"
          // https://gitlab.hq.meandair.com/core/product/-/issues/1850#note_28224
          return Math.round(q2u(this.getWindAtIndex(index)?.speed, "kt") ?? -1) === 0;
        },
        filterTimesteps<T extends { time_steps?: {
          [key: string]: { meaning?: string } | string | undefined;
          valid_time?: string;
        }[] }>(
          obj: T | undefined, isWind = false
        ): T | undefined {
          if (obj == null) return undefined;

          if (obj.time_steps?.length === 0) {
            // in case of empty TAFs (#1828)
            if (isWind) {
              obj.time_steps[0] = {
                speed: {
                  meaning: "no data",
                },
                from_direction: {
                  meaning: "no data",
                },
                valid_time: report.local_time ?? "",
                gust_speed: {
                  meaning: "no data",
                }
              }
            } else {
              obj.time_steps[0] = {
                quantity: {
                  meaning: "no data",
                },
                valid_time: report.local_time ?? "",
              };
            }
          }

          const localTime = formatTimeToCompare(report.local_time ?? "", true);
          return {
            ...obj,
            time_steps: obj?.time_steps?.filter((_step, i, arr) => {
              if (i + 1 === arr.length) {
                return true;
              }
              const nextStep = arr[i + 1];
              if (nextStep == null) {
                return true;
              }

              const nextStepTime = formatTimeToCompare(nextStep?.valid_time ?? "", false);
              return localTime.isBefore(nextStepTime);
            }),
          };
        },
      });
    },
  });
}

export type WeatherDescribedType = ReturnType<typeof describeAirportWeatherReport>;

export type QuantityProperty =
  | "surface_visibility"
  | "ceiling_agl"
  | "air_temperature_2m_agl"
  | "air_pressure_qnh"
  | "cloud_cover"
  | "flight_rules";

function formatTimeToCompare(timeString: string, parseTime: boolean) {
  const indexOfTimeZone = timeString.indexOf("[")
  const timeStringISO8601 = timeString.substring(0, indexOfTimeZone >= 0 ? indexOfTimeZone : undefined);

  const st = spacetime(timeStringISO8601);
  if (parseTime) {
    st.second(0);
  }
  return st;
}
