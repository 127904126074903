import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { LogoutButton } from "components/button/logout/LogoutButton";
import { OpenWeatherButton } from "components/button/openWeather/OpenWeatherButton";
import { Stack } from "components/layout/Stack";
import { describeMutationResult } from "logic/api/result/describe";
import { describeOutsideNavigation } from "logic/navigation/outside/describe";
import { useResponsiveValue } from "logic/responsive/styles";
import { ResponsiveValue } from "logic/responsive/types";
import { describeUserMeQuery } from "logic/user/me/describe";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "store/hooks";
import { awdApi } from "store/reducers/awdApi";
import { consentApi } from "store/reducers/consentApi";
import IconSearch from "@mui/icons-material/Search";
import { localApi } from "store/reducers/localApi";
import { toastError } from "store/reducers/toastSlice";
import { NavigationFavoriteAirports } from "components/navigationItems/NavigationFavoriteAirports";
import { NavigationWebcam } from "components/navigationItems/NavigationWebcam";
import { NavigationMeteostation } from "components/navigationItems/NavigationMeteostation";
import { iconColor } from "utils/themes";
import { DebugModeAlert } from "components/errorAlert/DebugModeAlert";

export type OutsideNavigationDrawerProps = {
  open: boolean;
  width: string;
  onCloseButtonClick?: () => void;
  onSearchItemClick?: () => void;
  showWeatherDashboardNavigationItems?: ResponsiveValue<boolean>;
  isTabletSize?: boolean;
  weatherDashboardIcao?: string;
};

const { getItems, isItemSelected } = describeOutsideNavigation();

export const OutsideNavigationDrawer: FC<OutsideNavigationDrawerProps> = ({
  width,
  open,
  onCloseButtonClick,
  onSearchItemClick,
  ...props
}) => {
  const { pathname } = useLocation();
  const { isLoggedIn } = describeUserMeQuery(awdApi.endpoints.getUserMe.useQuery());
  const isMobileApp = localApi.endpoints.getMobileAppConfig.useQuery().data?.is_mobile_app ?? false;
  const dispatch = useDispatch();
  const consentQuery = consentApi.endpoints.getConsent.useQuery();
  const [revokeConsent] = consentApi.endpoints.revokeConsent.useMutation();
  const userRolesQuery = awdApi.endpoints.getMyRoles.useQuery();
  const showWeatherDashboardNavigationItems = useResponsiveValue(
    props.showWeatherDashboardNavigationItems
  );

  const menuItems = getItems({
    isMobileApp,
    isLoggedIn: isLoggedIn(),
    hasResolvedCookieConsent: consentQuery.data?.cookie_consent != null,
    showWeatherDashboardNavigationItems,
    weatherDashboardIcao: props.weatherDashboardIcao,
    isTabletSize: props.isTabletSize,
    roles: userRolesQuery.data,
  });

  return (
    <Drawer open={open} variant="persistent" anchor="right">
      <Stack
        gap="1rem"
        horizontal
        css={{
          padding: "0.75rem 0.75rem 1rem 1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onCloseButtonClick}
          edge="start"
        >
          <ChevronRightIcon />
        </IconButton>
      </Stack>

      <List css={{ width }}>
        {menuItems.map((item) =>
          item.type === "favorite_airports" ? (
            <NavigationFavoriteAirports key="fav-airport" />
          ) : item.type === "webcam" ? (
            <NavigationWebcam key="webcam" />
          ) : item.type === "meteostation" ? (
            <NavigationMeteostation key="meteostation" />
          ) : (
            <ListItem key={item.title} disablePadding>
              <ListItemButton
                selected={isItemSelected(item, pathname)}
                {...(item.type === "page"
                  ? { href: item.page.path, onClick: () => {} }
                  : {
                      onClick: async () => {
                        if (item.type === "revoke_cookie_consent") {
                          const result = await revokeConsent().then(describeMutationResult);
                          if (result.error) {
                            dispatch(toastError(result.error, "Failed to revoke cookie consent."));
                            return;
                          }
                        }
                        if (item.type === "search_airport") {
                          onSearchItemClick?.();
                        }
                      },
                    })}
              >
                {item.type === "page" && item.page.Icon && (
                  <ListItemIcon>
                    <item.page.Icon />
                  </ListItemIcon>
                )}
                {item.type === "search_airport" && (
                  <ListItemIcon>
                    <IconSearch htmlColor={iconColor} />
                  </ListItemIcon>
                )}
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      <DebugModeAlert />
      <div css={{ flexGrow: 1 }} />

      {isLoggedIn() && (
        <Stack gap="0.5rem" css={{ paddingBottom: "1rem" }}>
          <OpenWeatherButton />
          <LogoutButton />
        </Stack>
      )}
    </Drawer>
  );
};
