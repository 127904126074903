import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import ForecastContainer from "./ForecastContainer";
import { themes } from "utils/themes";
import { useSelector } from "react-redux";
import { responsive } from "mixins";
import { TextWeatherReport } from "components/Dashboard/textWeatherReport/TextWeatherReport";
import { useAirportWeather } from "logic/weather/hooks";
import { Spacetime } from "spacetime";
import { useObserveElementSize } from "utils/hooks";
import { useMySettings } from "logic/user/me/settings/hooks";
import { stopOtherEvents } from "utils/general";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@mui/material";
import { RootState } from "store/store";
import { zUnitValue } from "awd-server-api";
import { z } from "zod";
import SwitchWithRecalc from "components/_buttons/SwitchWithRecalc";
import { TitleSection } from "../weatherNow/WeatherNow";
import { FlightRulesCode } from "store/reducers/localStateSlice";

export const zWeatherForecastElement = z.object({
  speed: zUnitValue.optional(),
  from_direction: zUnitValue.optional(),
  valid_time: z.string().optional(),
  gust_speed: zUnitValue.optional(),
  empty: z.boolean().optional(),
});
export const zWeatherForecastExtendedType = z.array(zWeatherForecastElement);

export type WeatherForecastElement = z.infer<typeof zWeatherForecastElement>;
export type WeatherForecastExtendedType = z.infer<typeof zWeatherForecastExtendedType>;

type WeatherForecastType = { className?: string };

const WeatherForecast = (props: WeatherForecastType) => {
  const {
    ref,
    size: { width },
  } = useObserveElementSize();

  const [forecastBounds, setForecastBounds] = useState<[number, number]>([0, 5]);
  const [timeIntervals] = useState(["NOW", "+1", "+2", "+3", "+4", "+5"]);
  const [click, setClick] = useState(0);
  const settings = useMySettings().describeOrDefault();
  const weather = useAirportWeather().report()?.withSettings(settings.settings);

  const awTaf = useSelector((state: RootState) => state.localState.toggledToAwTafWeatherSource);

  const forecast = weather?.selectForecastSource({ toggledToAw: awTaf });

  const flightRules = forecast?.getFlightRules();
  const visibility = forecast?.getVisibility();
  const ceiling = forecast?.getCeiling();
  const cloudCover = forecast?.getCloudCover();
  const wind = forecast?.getWind();

  useEffect(() => {
    setClick(0);
    if (!width) return;
    if (width >= 900) {
      setForecastBounds([0, 5]);
    } else if (width >= 780) setForecastBounds([0, 4]);
    else if (width >= 600) setForecastBounds([0, 3]);
    else if (width >= 500) setForecastBounds([0, 2]);
    else if (width >= 400) setForecastBounds([0, 1]);
    else {
      setForecastBounds([1, 1]);
      setClick(1);
    }
  }, [width]);

  const showMoveButtons = forecastBounds[0] !== 0 || forecastBounds[1] !== 5;

  const moveRight = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    stopOtherEvents(e);

    if (forecastBounds[1] < 5) {
      setForecastBounds([forecastBounds[0] + 1, forecastBounds[1] + 1]);
      setClick(click + 1);
    }
  };
  const moveLeft = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    stopOtherEvents(e);
    e.stopPropagation();
    if (forecastBounds[0] > 0) {
      setForecastBounds([forecastBounds[0] - 1, forecastBounds[1] - 1]);
      setClick(click - 1);
    }
  };

  const containerWithoutData = () => {
    if (!wind || !wind.time_steps) return;
    const missingItems = forecastBounds[1] - (wind?.time_steps.length - 1);
    const arrayMissingItems: WeatherForecastExtendedType = [];
    for (let i = 0; i < missingItems; i++) {
      arrayMissingItems.push({ empty: true });
    }
    const windWithAddedItems: WeatherForecastExtendedType =
      wind?.time_steps.concat(arrayMissingItems);

    return (
      wind &&
      windWithAddedItems.map((w, index) => {
        if (index >= forecastBounds[0] && index <= forecastBounds[1]) {
          if (!flightRules?.time_steps || !flightRules?.time_steps[index]) return <></>;
          if (!visibility?.time_steps || !visibility?.time_steps[index]) return <></>;
          if (!ceiling?.time_steps || !ceiling?.time_steps[index]) return <></>;
          if (!cloudCover?.time_steps || !cloudCover?.time_steps[index]) return <></>;
          return (
            // "data d0" up to "data d5"
            <div className={`data d${index}`} key={`withoutData ${index}`}>
              <ForecastContainer
                flightRules={flightRules?.time_steps[index]?.quantity}
                visibility={visibility?.time_steps[index]?.quantity}
                ceiling={ceiling?.time_steps[index]?.quantity}
                cloudCover={cloudCover?.time_steps[index]?.quantity}
                wind={w}
                index={index}
                empty={!!w?.empty}
              />
            </div>
          );
        } else return <></>;
      })
    );
  };

  const containerWithData = () => {
    if (!wind || !wind.time_steps || !visibility || !visibility.time_steps) return;
    return wind.time_steps.map((w, index) => {
      if (index >= forecastBounds[0] && index <= forecastBounds[1]) {
        if (!flightRules?.time_steps || !flightRules?.time_steps[index]) return <></>;
        if (!visibility?.time_steps || !visibility?.time_steps[index]) return <></>;
        if (!ceiling?.time_steps || !ceiling?.time_steps[index]) return <></>;
        if (!cloudCover?.time_steps || !cloudCover?.time_steps[index]) return <></>;
        return (
          // "data d0" up to "data d5"
          <div className={`data d${index}`} key={`withData ${index}`}>
            <ForecastContainer
              flightRules={flightRules?.time_steps[index]?.quantity}
              visibility={visibility?.time_steps[index]?.quantity}
              ceiling={ceiling?.time_steps[index]?.quantity}
              cloudCover={cloudCover?.time_steps[index]?.quantity}
              wind={w}
              index={index}
            />
          </div>
        );
      } else return <></>;
    });
  };

  if (!wind || !wind.time_steps) return null;

  const flightRulesMeaning = flightRules?.time_steps?.[0]?.quantity?.meaning ?? "no data";
  const flightRulesCode = (flightRulesMeaning === "no data" ? "default" : flightRulesMeaning.toLocaleLowerCase()) as FlightRulesCode;

  return (
    <StyledForecast ref={ref} className={props.className}>
      <div className="labels">
        <TitleSection>Weather Forecast</TitleSection>
        <SwitchWithRecalc weather={weather} theme={themes[flightRulesCode]} source={"taf"} />
      </div>

      <div className="forecastTable">
        <div className="buttonLeft" css={!showMoveButtons ? { display: "none" } : undefined}>
          <Button type="button" variant="text" sx={{ minWidth: "unset" }} onClick={moveLeft}>
            <ArrowBackIosNewIcon sx={{ fontSize: "1rem", color: "black" }} />
          </Button>
        </div>

        {wind && wind.time_steps?.length > forecastBounds[0] && (
          <div className="timeStart">
            {weather?.getFormattedLocalTime("input", "short", wind?.time_steps[forecastBounds[0]]?.valid_time)}
          </div>
        )}

        <StyledTimeBar width={width}>
          {/* rendering time intervals - NOW, +1, +2 */}
          {timeIntervals &&
            timeIntervals.map((el, i) => {
              if (i >= forecastBounds[0] && i <= forecastBounds[1]) {
                return (
                  <StyledTimeInterval className={`interval${i - click}`} key={`timeInterval ${i}`}>
                    <span>{el}</span>
                  </StyledTimeInterval>
                );
              } else return null;
            })}

          {/* rendering inner time indication - 8:00, 9:00, 10:00 */}
          {wind.time_steps.map((x, i, a) => {
            if (i > 0 && i > forecastBounds[0] && i <= forecastBounds[1]) {
              return (
                <StyledTimeIndication className={`t${i - click}`} key={`innerTime ${i}`}>
                  <div>{weather?.getFormattedLocalTime("input", "short", a[i]?.valid_time)}</div>
                </StyledTimeIndication>
              );
            } else return null;
          })}
        </StyledTimeBar>

        {/* rendering last time indication - 12:00 */}
        {wind.time_steps.map((x, i, a) => {
          if (i === forecastBounds[1]) {
            return (
              <div className="timeEnd" key={`endInterval ${i}`}>
                {wind && weather?.getFormattedLocalTime("input", "short", a[i]?.valid_time, (s: Spacetime) => s.add(1, 'hour'))}
              </div>
            );
          } else {
            return null;
          }
        })}

        <div className="buttonRight" css={!showMoveButtons ? { display: "none" } : undefined}>
          <Button type="button" variant="text" sx={{ minWidth: "unset" }} onClick={moveRight}>
            <ArrowForwardIosIcon sx={{ fontSize: "1rem", color: "black" }} />
          </Button>
        </div>

        <StyledWeatherForecast width={width}>
          {/* rendering data */}
          {wind.time_steps.length - 1 >= forecastBounds[1]
            ? containerWithData()
            : containerWithoutData()}
        </StyledWeatherForecast>
      </div>

      <div
        className="weatherReport"
        css={{
          paddingBottom: "1rem",
        }}
      >
        <TitleSection>{awTaf ? "aw-taf" : "taf"}</TitleSection>
        <TextWeatherReport source={awTaf ? "aw-taf" : "taf"} />
      </div>
    </StyledForecast>
  );
};

const StyledForecast = styled.div`
  display: flex;
  flex-direction: column;

  ${responsive.mobilP} {
    max-width: 390px;
  }
  ${responsive.mobilS} {
    max-width: 290px;
  }

  .labels {
    margin-top: 1.5rem;
    padding-left: 1.2rem;
  }

  .forecastTable {
    display: grid;
    grid-template-columns: 0.5fr min-content auto min-content 0.5fr;
  }

  .weatherReport {
    width: 60%;

    margin-top: 1rem;
    margin-bottom: 1rem;

    padding-left: 1.2rem;

    ${responsive.hambrg} {
      width: 100%;
      margin-bottom: 0.7rem;
    }
    ${responsive.mobilP} {
      margin-left: 0.2rem;
      margin-right: 0.5rem;
    }
  }

  button {
    border: 2px solid #323233be;
    border-radius: 0.3rem;
    background-color: transparent;
    cursor: pointer;
    height: 100%;
    padding: 0 10px;
  }

  .buttonLeft {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-right: 0.25rem;
  }
  .buttonRight {
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-left: 0.25rem;
  }
  .timeStart {
    font-size: 12px;
    background-color: #f1f1f2;
    color: #7d7d7e;

    width: 2.1rem;
    overflow: visible;

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-left: 0.2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    border-radius: 1rem 0 0 1rem;
  }
  .timeEnd {
    font-size: 12px;
    background-color: #f1f1f2;
    color: #7d7d7e;
    width: 2.1rem;

    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-right: 0.2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    border-radius: 0 1rem 1rem 0;
  }
`;

const StyledTimeInterval = styled.div`
  grid-row-start: 1;
  grid-row-end: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    background-color: #e0dfdfcc;
    border-radius: 1rem;
    width: 90%;
    padding: 0.2rem 0;
  }

  ${responsive.highlights} {
    font-size: 14px;
  }

  &.interval0 {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  &.interval1 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  &.interval2 {
    grid-column-start: 3;
    grid-column-end: 4;
  }
  &.interval3 {
    grid-column-start: 4;
    grid-column-end: 5;
  }
  &.interval4 {
    grid-column-start: 5;
    grid-column-end: 6;
  }
  &.interval5 {
    grid-column-start: 6;
    grid-column-end: 7;
  }
`;

const StyledTimeIndication = styled.div`
  grid-row-start: 1;
  grid-row-end: 2;

  font-size: 12px;
  color: #7d7d7e;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    width: 3em;
  }

  &.t1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  &.t2 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  &.t3 {
    grid-column-start: 3;
    grid-column-end: 5;
  }
  &.t4 {
    grid-column-start: 4;
    grid-column-end: 6;
  }
  &.t5 {
    grid-column-start: 5;
    grid-column-end: 7;
  }
`;

const StyledTimeBar = styled.div<{ width: number | undefined }>`
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;

  padding: 0.5rem 0;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  text-align: center;

  color: #58585b;
  background-color: #f1f1f2;

  ${({ width }) => {
    if (!width) return undefined;
    return {
      gridTemplateColumns:
        width < 400
          ? "1fr"
          : width < 500
          ? "repeat(2, 1fr)"
          : width < 600
          ? "repeat(3, 1fr)"
          : width < 780
          ? "repeat(4, 1fr)"
          : width < 900
          ? "repeat(5, 1fr)"
          : undefined,
    };
  }}
`;

const StyledWeatherForecast = styled.div<{ width: number | undefined }>`
  grid-column-start: 3;
  grid-column-end: 4;

  grid-row-start: 3;
  grid-row-end: 4;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;

  grid-column-gap: 1rem;
  ${responsive.mobilP} {
    grid-column-gap: 0.4rem;
  }

  .data {
    border: 1px solid #58585b96;
    border-radius: 1rem;

    width: 100%;
    text-align: center;
    padding-bottom: 0.5rem;
  }

  ${({ width }) => {
    if (!width) return undefined;
    return {
      gridTemplateColumns:
        width < 400
          ? "1fr"
          : width < 500
          ? "repeat(2, 1fr)"
          : width < 600
          ? "repeat(3, 1fr)"
          : width < 780
          ? "repeat(4, 1fr)"
          : width < 900
          ? "repeat(5, 1fr)"
          : undefined,
    };
  }}
`;

export default WeatherForecast;
